.score-bg{
    background-color: rgb(60, 57, 57); padding: 9px;
    }

#matchScore {
    display: inline-table;
    background: #000;
}

.ball-runs {
    background: #ffc11c;
    color: #000 !important;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    font-size: 11px;
}


.lastball-runs {
    margin-left: 3px;
    background: #27a6c3;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: inline-block;
    line-height: 23px;
    text-align: center;
    color: #fff;
    font-size: 8px;
}

.currentOver {
    margin-left: 3px;
    background: #27a6c3;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: inline-block;
    line-height: 23px;
    text-align: center;
    color: #fff;
    font-size: 8px;
}

.balls-0 {
    background-color: grey !important;
}

.balls-4 {
    background-color: #ffc11c !important;
}

.balls-6 {
    background: green !important;

}

.balls-w {
    background: Red !important;

}

.lastball-runs {
    margin-left: 3px;
    background: #27a6c3;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: inline-block;
    line-height: 23px;
    text-align: center;
    color: #fff;
    font-size: 8px;
}

.grey-background {
    background: #e5e5e5;
    padding: 6px 0px 6px 0px;
    border-top: 1px ridge lightgrey;
}

.score-text {
    font-size: 14px;
    color: white;
    font-weight: bold;
}

.match-commentary {
    color: #e31111;
    font-weight: bold;
    font-size: 14px;
}

.desktop-balls-over {
    display: inline;
}

.mobile-balls-over {
    display: none !important;
}

@media only screen and (max-width: 767px) {
    .desktop-balls-over {
        display: none;
    }

    .mobile-balls-over {
        display: flex !important;
    }
}

/* tannis css */

.score-main {

    background-size: 100% 100%;
    position: relative;
    padding: 10px;
    width: 100%;
    float: left;
    min-height: 120px;
    color: #fff;
}

.score-main-alt {
    padding: 0;
    min-height: 90px;
    font-size: 11px;
}

.score-body {
    background: rgba(0, 0, 0, 0.6);
    height: 60px;
    transform: translateY(35%);
    display: flex;
    border-radius: 4px;
    align-items: center;
}

.team-box {
    flex: 1;
    color: #fff;
    padding: 10px;
    vertical-align: middle;
}

.target_center {
    background: transparent;
}

.team-left {
    text-align: left;
}

.team-right {
    text-align: right;
}

.ball-update {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -14px;
    overflow: hidden;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    border-top: solid 1px #696969;
}

.lt {
    width: 100%;
    float: left;
    margin-bottom: 0;
}

.rt {
    width: 100%;
    float: right;
    margin-bottom: 0;
}

.score_team,
.score_area .runners-table {
    width: 100%;

    background-color: #000;
    background-image: linear-gradient(#2b2b2b 1px, transparent 0), linear-gradient(90deg, #2b2b2b 1px, transparent 0);
    background-size: 4px 4px, 4px 4px;
    background-position: -2px -2px, -2px -2px;
    float: left;
    color: #fff;
    padding: 10px;
}

.current_set {
    background: #303030;
    color: #0c0;
    width: 100%;
    float: left;
    text-align: center;
    padding: 5px;
    font-size: 15px;
}

td.home-runner {
    padding: 10px;
    border-bottom: 1px solid #454545;
    border-right: 1px solid #454545;
}

.sc {
    float: left;
    margin: 0 15px;
}
.score_team,  .runners-table {
    width: 100%;
    background:#000;
    text-align: center;
    background-image: linear-gradient(#2b2b2b 1px, transparent 0), linear-gradient(90deg, #2b2b2b 1px, transparent 0);
    background-size: 4px 4px,4px 4px;
    background-position: -2px -2px,-2px -2px;
    float: left;
    color: #fff;
    padding: 10px;
}

 

.score-body {
   
    background: rgba(0,0,0,0.6);
    height: 60px;
    -webkit-transform: translateY(35%);
    transform: translateY(35%);
    display: flex;
    height: 60px;
    border-radius: 4px;
    align-items: center;
}

td.home-runner {
    padding: 10px 10px;
    border-bottom: 1px solid #454545;
    border-right: 1px solid #454545;
}
.ui-score-container {
    color: #0c0;
}